import React from "react";
import asyncComponent from "./AsyncComponent";
import { Result } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";

const HomePage = asyncComponent(() => import("./pages/HomePage"));
const queryClient = new QueryClient();

function App() {
  if (document.cookie.indexOf("whoami=adonmo_employee") !== -1) {
    return (
      <QueryClientProvider client={queryClient}>
        <HomePage />
      </QueryClientProvider>
    );
  } else {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
    );
  }
}

export default App;
